<template>
  <div>
    <el-table :height="height" element-loading-text="各位老板们数据正在加载中，请稍等..." v-loading="loading" style="padding-left: 10px;padding-right: 10px;" :data="tableData"
              :header-cell-style="{backgroundColor: '#fafafa',fontSize: '12px',color: '#1A1C1F'}"
    >
      <template
        v-for="(item,index) in tableHeader"
      >
        <el-table-column
            v-if="show"
          :key="index"
          show-overflow-tooltip
          style="border: 0px"
          :width="item.width?item.width:'auto'"
          :min-width="item.minWidth"
          :prop="item.prop"
          :align="item.align?item.align:'center'"
          :label="item.label"
        >
          <template slot-scope="scope">
            <slot v-if="item.scopeStatus" :name="item.prop" :row="scope.row" />
            <template v-else>
              <span style="font-size: 12px;color: #86909C;">{{ scope.row[item.prop] }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
            v-else
            :key="index"
            style="border: 0px"
            :width="item.width?item.width:'auto'"
            :min-width="item.minWidth"
            :prop="item.prop"
            :align="item.align?item.align:'center'"
            :label="item.label"
        >
          <template slot-scope="scope">
            <slot v-if="item.scopeStatus" :name="item.prop" :row="scope.row" />
            <template v-else>
              <span style="font-size: 12px;color: #86909C;">{{ scope.row[item.prop] }}</span>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "HssTable",
  data(){
    return{
      spanArr: []
    }
  },
  props: {
    show:{
      type: Boolean,
      default: function (){
        return true
      }
    },
    loading:{
      type: Boolean,
      default: function (){
        return false
      }
    },
    tableData: {
      type: Array,
      default: function() {
        return []
      }
    },
    height:{
      type: Number,
      default: function (){
        return null
      }
    },
    tableHeader: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  mounted() {
  },
  methods:{

  }
}
</script>

<style scoped>
*{
  font-family: diy-font;
}
</style>
