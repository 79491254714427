import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "../components/Layout.vue";
import Home from "../views/home/Home.vue";
import Index from "../views/mch/Index.vue";
import InputPieceSh from "@/views/mch/InputPieceSh.vue";
import Login from "@/views/login/login.vue";

Vue.use(VueRouter)

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/',
    name: 'index',
    component: Layout,
    redirect: '/agent/list',
    meta: {
      title: '工作台',
      hidden: true,
      raking: 1,
      auth: ['A','M'],
      icon: 'iconfont el-icon-luzhang'
    },
    children: [
      {
        path: 'home',
        name: 'home',
        meta: {
          title: '工作台',
        },
        component: () => import('@/views/home/Home.vue')
      },
      {
        path: 'pieceManager',
        name: 'pieceManager',
        meta: {
          title: '审核管理',
        },
        component: () => import('@/views/mch/InputPieceSh.vue')
      },
    ]
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   raking: 1,
  //   auth: ['A','M'],
  //   meta: {
  //     title: '工作台',
  //     icon: 'iconfont el-icon-moxing'
  //   }
  // },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      hidden: true
    },
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      hidden: true
    },
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/alit',
    name: 'alit',
    meta: {
      title: '登录',
      hidden: true
    },
    component: () => import('@/views/alit.vue')
  },
  {
    path: '/alipc',
    name: 'alipc',
    meta: {
      title: '登录',
      hidden: true
    },
    component: () => import('@/views/alit2.vue')
  },
  {
    path: '/ts',
    name: 'ts',
    meta: {
      title: '登录',
      hidden: true
    },
    component: () => import('@/views/account/Ts.vue')
  },
  {
    path: '/all',
    name: 'ts',
    meta: {
      title: '登录',
      hidden: true
    },
    component: () => import('@/views/bigscreen/index.vue')
  },
  {
    path: '/h5pay',
    name: 'pay',
    meta: {
      title: '登录',
      hidden: true
    },
    component: () => import('@/views/pay/h5Pay.vue')
  },
  {
    path: '/paySuccess',
    name: 'pay',
    meta: {
      title: '登录',
      hidden: true
    },
    component: () => import('@/views/pay/paySuccess.vue')
  },
  {
    path: '/alipay',
    name: 'alipay',
    meta: {
      title: '登录',
      hidden: true
    },
    component: () => import('@/views/pay/alipay.vue')
  },
  {
    path: '/wxPay',
    name: 'wxPay',
    meta: {
      title: '登录',
      hidden: true
    },
    component: () => import('@/views/pay/wxPay.vue')
  },
  {
    path: '/rwmPay',
    name: 'rwmPay',
    meta: {
      title: '登录',
      hidden: true
    },
    component: () => import('@/views/pay/rwmPay.vue')
  },
  {
    path: '/alipaysuccess',
    name: 'paysuccess',
    meta: {
      title: '工作台',
      hidden: true
    },
    component: () => import('@/views/pay/alipaySuccess.vue')
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      title: '工作台',
      hidden: true
    },
    component: () => import('@/views/test.vue')
  },{
    path: '/test2',
    name: 'test2',
    meta: {
      title: '工作台',
      hidden: true
    },
    component: () => import('@/views/test2.vue')
  },
  {
    path: '/agent',
    name: 'agent',
    meta: {
      title: '代理商管理',
      raking: 2,
      auth: [],
      icon: 'iconfont el-icon-tongxun'
    },
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'agentList',
        meta: {
          auth: [],
          upTitle: '代理商管理',
          title: '代理商列表'
        },
        component: () => import('@/views/agent/Index.vue')
      },
      {
        path: 'user-detail',
        name: 'userDetail',
        meta: {
          title: '详情页',
          upTitle: '代理商管理',
          hidden: true
        },
        component: () => import('@/views/mch/component/MchDetail.vue')
      },
    ]
  },
  {
    path: '/order',
    name: 'order',
    component: Layout,
    meta: {
      title: '订单管理',
      raking: 7,
      auth: [],
      icon: 'iconfont el-icon-ziyuan-tuceng'
    },
    children: [
      {
        path: 'list',
        name: 'list',
        meta: {
          auth: [],
          title: '订单列表',
          isNew: true,
          upTitle: '订单列表',
        },
        component: () => import('@/views/channel/OrderList.vue')
      }
    ]
  },
  {
    path: '/channel',
    name: 'channel',
    component: Layout,
    meta: {
      title: '通道管理',
      raking: 7,
      auth: ['admin'],
      icon: 'iconfont el-icon-tansuo2'
    },
    children: [
      {
        path: 'list',
        name: 'list',
        meta: {
          auth: ['admin'],
          title: '通道列表',
          isNew: true,
          upTitle: '通道列表',
        },
        component: () => import('@/views/channel/List.vue')
      }
    ]
  },
  {
    path: '/app',
    name: 'app',
    component: Layout,
    meta: {
      title: '应用管理',
      raking: 7,
      auth: ['admin'],
      icon: 'iconfont el-icon-wenjianjia'
    },
    children: [
      {
        path: 'list',
        name: 'list',
        meta: {
          auth: ['admin'],
          title: '应用列表',
          isNew: true,
          upTitle: '应用列表',
        },
        component: () => import('@/views/app/List.vue')
      }
    ]
  },
  // {
  //   path: '/black',
  //   name: 'black',
  //   component: Layout,
  //   meta: {
  //     title: '黑名单管理',
  //     raking: 7,
  //     auth: ['admin'],
  //     icon: 'iconfont el-icon-yulan'
  //   },
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'list',
  //       meta: {
  //         auth: ['admin'],
  //         title: '黑名单列表',
  //         isNew: true,
  //         upTitle: '黑名单列表',
  //       },
  //       component: () => import('@/views/account/BlackList.vue')
  //     }
  //   ]
  // },
  {
    path: '/merchant',
    name: 'black',
    component: Layout,
    meta: {
      title: '进件管理',
      raking: 8,
      auth: ['admin'],
      icon: 'iconfont el-icon-yulan'
    },
    children: [
      {
        path: 'add',
        name: 'add',
        meta: {
          auth: ['admin'],
          title: '新增进件',
          isNew: true,
          upTitle: '新增进件',
        },
        component: () => import('@/views/merchants/add.vue')
      },
      {
        path: 'list',
        name: 'list',
        meta: {
          auth: ['admin'],
          title: '进件列表',
          isNew: true,
          upTitle: '进件列表',
        },
        component: () => import('@/views/merchants/index.vue')
      }
    ]
  },
  {
    path: '/pay',
    name: 'black',
    component: Layout,
    meta: {
      title: '支付测试',
      raking: 8,
      auth: ['admin'],
      icon: 'iconfont el-icon-yulan'
    },
    children: [
      {
        path: 'test',
        name: 'test',
        meta: {
          auth: ['admin'],
          title: '支付测试',
          isNew: true,
          upTitle: '支付测试',
        },
        component: () => import('@/views/pay/test.vue')
      }
    ]
  },
  {
    path: '/ts',
    name: 'ts',
    meta: {
      title: '投诉管理',
      raking: 7,
      hidden: true,
      auth: ['admin'],
      icon: 'iconfont el-icon-ziyuan-tuceng'
    }
  },
  {
    path: '/all',
    name: 'ts',
    meta: {
      title: '投诉管理',
      raking: 8,
      hidden: true,
      auth: ['admin'],
      icon: 'iconfont el-icon-ziyuan-tuceng'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

import store from '@/store'
router.beforeEach((to, from, next) => {
  console.log(from,"----",to)
  if (to.path == '/ts' || to.path == '/alit' || to.path == '/alipc' || to.path == '/test2' || to.path == '/test' || to.path == '/alipaysuccess' || to.path == '/login' || to.path == '/pay' || to.path == '/authPay' || to.path == '/h5-piece' || to.path == '/wxPay' || to.path == '/rwmPay' || to.path == '/alipay' || to.path == '/h5pay' || to.path === '/paySuccess') {
      next()
  } else {
    if (store.getters.token) {
      // let hidden = menus.filter(x => x.index === to.path)[0].hidden.indexOf(store.getters.userInfo.id.substring(0,1)) !== -1;
      // console.log(hidden,"-=----",to.path,store.getters.userInfo.id)
      // if (store.getters.userInfo.id.substring(0,1) == "M"){
      //     next("/order/list")
      // }else{
      next()
      // }
      // if (hidden) {
      //     next()
      // } else {
      //     next("/order/list")
      // }
    } else {
        next("/login")
    }
  }
})
export default router;
