import http from '@/libs/axios';

let pro = process.env.VUE_APP_BASE_API

/**
 * 代理商登录
 * @param data
 * @returns {*}
 */
export function adminLogin(data){
    return http({
        url: `${pro}/user/login`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 发送短信验证码
 * @param query
 * @returns {*}
 */
export function sendSms(query){
    return http({
        url: `${pro}/v1/user/sendMessage`,
        method: 'get',
        params: query
    })
}

/**
 * 新增代理商
 * @param data
 * @returns {*}
 */
export function addAgent(data){
    return http({
        url: `${pro}/v1/user/addAgent`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 查询代理商列表
 * @param data
 * @returns {*}
 */
export function agentList(data){
    return http({
        url: `${pro}/v1/user/list`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 删除代理商
 * @param id
 * @returns {*}
 */
export function delAgent(id){
    return http({
        url: `${pro}/v1/user/delAgent?id=${id}`,
        method: 'post'
    })
}

/**
 * 删除代理商
 * @param id
 * @returns {*}
 */
export function aliPayCode(data){
    return http({
        url: `${pro}/v1/public/apliayCode`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * ocr识别
 * @param url
 * @param type
 * @returns {*}
 */
export function uploadFileOcr(url,type){
    return http({
        url: `${pro}/v1/upload?url=${url}&type=${type}`,
        method: 'post'
    })
}

/**
 * 获取类目
 * @param url
 * @param type
 * @returns {*}
 */
export function getMcc(type){
    return http({
        url: `${pro}/v1/public/getMcc?type=${type}`,
        method: 'get'
    })
}

/**
 * 获取所有银行
 * @param url
 * @param type
 * @returns {*}
 */
export function getBrankName(city){
    return http({
        url: `${pro}/v1/public/getBrankNameList?city=${city}`,
        method: 'get'
    })
}
/**
 * 获取指定城市的指定银行的支行
 * @param url
 * @param type
 * @returns {*}
 */
export function getSubBrankList(city,brankName){
    return http({
        url: `${pro}/v1/public/getSubBrankList?city=${city}&brankName=${brankName}`,
        method: 'get'
    })
}

/**
 * 获取代理商利润
 * @param url
 * @param type
 * @returns {*}
 */
export function getMoney(id){
    return http({
        url: `${pro}/v1/user/getMoney?id=${id}`,
        method: 'get'
    })
}

/**
 * 加载城市银行类目数据
 * @returns {*}
 */
export function initData(){
    return http({
        url: `${pro}/v1/app/initData`,
        method: 'GET'
    })
}

/**
 * 查询平台所有渠道
 * @returns {*}
 */
export function getTenantList(){
    return http({
        url: `${pro}/v1/channel/list`,
        method: 'get'
    })
}


/**
 * 查询安全发  记账本金额  签约人信息
 * @param no
 * @param id
 * @param channel
 * @returns {*}
 */
export function queryAgreement(no,id,channel){
  return http({
    url: `${pro}/v1/public/queryAgreement/${channel}/${no}/${id}`,
    method: 'get'
  })
}


/**
 * 记账本充值
 * @param data
 * @returns {*}
 */
export function bookPay(data){
  return http({
    url: `${pro}/v1/public/bookPay`,
    method: 'post',
    requestBody: true,
    data
  })
}

/**
 * 记账本转账-到支付宝
 * @param data
 * @returns {*}
 */
export function trans(data){
  return http({
    url: `${pro}/v1/public/trans`,
    method: 'post',
    requestBody: true,
    data
  })
}

/**
 * 记账本转账-到银行卡
 * @param data
 * @returns {*}
 */
export function transCard(data){
  return http({
    url: `${pro}/v1/public/transCard`,
    method: 'post',
    requestBody: true,
    data
  })
}

/**
 * 记账本转账-到银行卡
 * @param data
 * @returns {*}
 */
export function downloadPz(id,aggrentNo,channel){
  return http({
    url: `${pro}/v1/public/pz/${channel}/${id}/${aggrentNo}`,
    method: 'get'
  })
}

/**
 * 查询记账本转账历史记录列表
 * @param data
 */
export function queryHistoryTrans(data){
    return http({
        url: `${pro}/v1/public/queryHistoryTrans`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 查询记账本转账历史记录列表
 * @param data
 */
export function queryHistoryTransExport(data){
    return http({
        url: `${pro}/v1/public/queryHistoryTransExport`,
        method: 'post',
        requestBody: true,
        data
    })
}


/**
 * 签约安全发
 * @param no
 * @param id
 * @returns {*}
 */
export function pageSign(id){
  return http({
    url: `${pro}/sign?id=${id}`,
    method: 'post'
  })
}

/**
 * 统计代付
 * @param data
 */
export function totalTransfer(id){
    return http({
        url: `${pro}/v1/public/totalTransfer/${id}`,
        method: 'post'
    })
}

export function kjTransfer(agentId,type){
  return http({
    url: `${pro}/v1/public/kjTransfer?agentId=${agentId}&type=${type}`,
    method: 'get'
  })
}

export function signList(data){
  return http({
    url: `${pro}/user/getSignList`,
    method: 'post',
    requestBody: true,
    data
  })
}

export function addSign(data){
  return http({
    url: `${pro}/addSign`,
    method: 'post',
    requestBody: true,
    data
  })
}
export function checkSign(id){
  return http({
    url: `${pro}/querySign?id=${id}`,
    method: 'post'
  })
}

export function bookList(id){
    return http({
        url: `${pro}/book/querySignBookList?id=${id}`,
        method: 'post'
    })
}

export function createBook(data){
    return http({
        url: `${pro}/createBook`,
        method: 'post',
        requestBody: true,
        data
    })
}
export function queryChannel(channel,agentId){
  return http({
    url: `${pro}/v1/public/queryChannel/${channel}/${agentId}`,
    method: 'post'
  })
}

/**
 * 修改用户状态
 * @param data
 * @returns {*}
 */
export function updateUserStatus(data){
    return http({
        url: `${pro}/user/updateUserStatus`,
        method: 'post',
        data
    })
}

/**
 * 修改用户状态
 * @param data
 * @returns {*}
 */
export function updateUserRk(data){
  return http({
    url: `${pro}/user/updateUserRk`,
    method: 'post',
    data
  })
}


/**
 * 修改单笔额度配置
 * @param data
 * @returns {*}
 */
export function eduSettings(data){
    return http({
        url: `${pro}/user/eduSettings`,
        method: 'post',
        data
    })
}

/**
 * 查询代理商可授权的记账本
 * @param userNo
 * @returns {*}
 */
export function authBookList(upUserNo,userNo){
    return http({
        url: `${pro}/user/authBookList?userNo=${userNo}&upUserNo=${upUserNo}`,
        method: 'post'
    })
}

/**
 * 查询代理商签约的记账本列表
 * @param data
 */
export function bookLists(data){
    return http({
        url: `${pro}/book/list`,
        method: 'post',
        data
    })
}
/**
 * 查询代理商签约的记账本列表-前端控制
 * @param data
 */
export function listView(data){
    return http({
        url: `${pro}/book/listView`,
        method: 'post',
        data
    })
}
/**
 * 查询通道列表
 * @param data
 */
export function getChannelList(data){
    return http({
        url: `${pro}/channel/list`,
        method: 'post',
        data
    })
}


/**
 * 查询指定的代理商代付记录列表
 * @param data
 * @returns {*}
 */
export function appList(data){
  return http({
    url: `${pro}/app/list`,
    method: 'POST',
    requestBody: true,
    data
  })
}

/**
 * 查询指定的代理商代付记录列表
 * @param data
 * @returns {*}
 */
export function appAdd(data){
  return http({
    url: `${pro}/app/add`,
    method: 'POST',
    requestBody: true,
    data
  })
}

/**
 * 查询指定的代理商代付记录列表
 * @param data
 * @returns {*}
 */
export function channelAppList(data){
  return http({
    url: `${pro}/channelApp/list`,
    method: 'POST',
    requestBody: true,
    data
  })
}
/**
 * 查询指定的代理商代付记录列表
 * @param data
 * @returns {*}
 */
export function unauthorizedList(data){
  return http({
    url: `${pro}/channelApp/unauthorizedList`,
    method: 'POST',
    requestBody: true,
    data
  })
}
/**
 * 查询指定的代理商代付记录列表
 * @param data
 * @returns {*}
 */
export function authApp(data){
  return http({
    url: `${pro}/channelApp/authApp`,
    method: 'POST',
    requestBody: true,
    data
  })
}
/**
 * 查询指定的代理商代付记录列表
 * @param data
 * @returns {*}
 */
export function addChannel(data){
  return http({
    url: `${pro}/channel/add`,
    method: 'POST',
    requestBody: true,
    data
  })
}

/**
 * 查询订单列表
 * @param data
 * @returns {*}
 */
export function orderList(data){
  return http({
    url: `${pro}/order/list`,
    method: 'POST',
    requestBody: true,
    data
  })
}
/**
 * 查询订单列表
 * @param data
 * @returns {*}
 */
export function orderStatic(data){
  return http({
    url: `${pro}/order/orderStatic`,
    method: 'POST',
    requestBody: true,
    data
  })
}
/**
 * 查询订单列表
 * @param data
 * @returns {*}
 */
export function channelUserUnauthorizedList(data){
  return http({
    url: `${pro}/channelUser/unauthorizedList`,
    method: 'POST',
    requestBody: true,
    data
  })
}
/**
 * 查询订单列表
 * @param data
 * @returns {*}
 */
export function channelUserAuthorizedList(data){
  return http({
    url: `${pro}/channelUser/authorizedList`,
    method: 'POST',
    requestBody: true,
    data
  })
}

/**
 * 授权记账本
 * @param data
 */
export function authBook(data){
    return http({
        url: `${pro}/channelUser/authChannel`,
        method: 'post',
        data
    })
}
/**
 * 取消授权记账本
 * @param data
 */
export function cleanAuthBook(data){
    return http({
        url: `${pro}/channelUser/unAuthChannel`,
        method: 'post',
        data
    })
}

/**
 * 设置默认通道
 * @param data
 */
export function settingsSelected(data){
    return http({
        url: `${pro}/channelUser/settingsSelected`,
        method: 'post',
        data
    })
}

/**
 * 查询指定的代理商代付记录列表
 * @param data
 * @returns {*}
 */
export function updateChannel(data){
    return http({
        url: `${pro}/channel/update`,
        method: 'POST',
        requestBody: true,
        data
    })
}
/**
 * 查询黑名单列表
 * @param data
 * @returns {*}
 */
export function blacklist(data){
    return http({
        url: `${pro}/user/blacklist`,
        method: 'POST',
        requestBody: true,
        data
    })
}
/**
 * 新增黑名单
 * @param data
 * @returns {*}
 */
export function addBlacklist(data){
    return http({
        url: `${pro}/user/addBlacklist`,
        method: 'POST',
        requestBody: true,
        data
    })
}
/**
 * 删除黑名单
 * @param data
 * @returns {*}
 */
export function delBlacklist(id){
    return http({
        url: `${pro}/user/delBlacklist/${id}`,
        method: 'POST'
    })
}

/**
 * 查询列表
 * @returns {*}
 */
export function alipayAppList(){
  return http({
    url: `${pro}/alipay/appList`,
    method: 'get'
  })
}

/**
 * 修改授权应用状态
 * @returns {*}
 */
export function changeChannelApp(id,status){
  return http({
    url: `${pro}/channelApp/changeChannelApp/${id}/${status}`,
    method: 'POST'
  })
}

/**
 * 补单
 * @returns {*}
 */
export function callOrder(orderNo){
  return http({
    url: `${pro}/order/callOrder/${orderNo}`,
    method: 'POST'
  })
}

/**
 * 补单
 * @returns {*}
 */
export function orderLogs(orderNo){
  return http({
    url: `${pro}/order/orderLogs/${orderNo}`,
    method: 'POST'
  })
}
/**
 * 新增商户
 * @returns {*}
 */
export function merchantAdd(data){
  return http({
    url: `${pro}/merchant/add`,
    method: 'POST',
    requestBody: true,
    data
  })
}
/**
 * 服务列表
 * @returns {*}
 */
export function serviceList(data){
  return http({
    url: `${pro}/service/list`,
    method: 'POST'
  })
}
/**
 * 二级商户列表
 * @returns {*}
 */
export function merchantList(data){
  return http({
    url: `${pro}/merchant/list`,
    method: 'POST',
    requestBody: true,
    data
  })
}

export function merchantQuery(id){
  return http({
    url: `${pro}/merchant/query/${id}`,
    method: 'POST'
  })
}
