<template>
  <div style="display: flex;overflow: hidden">
    <div>
      <div style="width: 1100px;height: 200px;display: flex;" class="leftBox">
        <div style="padding: 20px">
          <div style="width: 320px">
            <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
            <span style="font-weight: bold;font-size: 14px;">今日账户收益情况</span>
            <span
              style="font-size: 12px;color: #86909C;margin-left: 15px;">更新时间：{{
                new Date().getFullYear() + '年' + parseInt(new Date().getMonth() + 1) + '月' + new Date().getDate() + '日'
              }}</span>
            <i class="el-icon-refresh-right"
               style="font-size: 12px;color: #86909C;margin-left: 10px;cursor: pointer;"></i>
          </div>
          <div style="margin-left: 40px;margin-top: 30px;display: flex;flex-direction: column;">
            <span style="font-size: 14px;color: #50565D;font-weight: bold">今日收益</span>
            <span style="font-size: 28px;font-weight: bold;margin-top: 10px">0</span>
            <span
              style="font-size: 12px;color: #50565D;font-weight: bold;margin-top: 10px;">昨日收益：<span>0</span></span>
            <span style="font-size: 11px;margin-top: 10px;color: #86909C;">较昨日多出：<span
              style="color: red;">0</span></span>
          </div>
        </div>
        <div style="width: 160px;">
          <div style="margin-left: 40px;margin-top: 69px;display: flex;flex-direction: column;width: 150px;">
            <span style="font-size: 14px;color: #50565D;font-weight: bold">今日订单金额</span>
            <span style="font-size: 28px;font-weight: bold;margin-top: 10px">
              <CountTo :startVal="0" :endVal="info.money"/>
            </span>
            <span
              style="font-size: 12px;color: #50565D;font-weight: bold;margin-top: 10px;">昨日订单金额：<span>  <CountTo :startVal="0" :endVal="info.preMoney"/></span></span>
            <span style="font-size: 11px;margin-top: 10px;color: #86909C;">较昨日多出：<span
              style="color: red;"><CountTo :startVal="0" :endVal="info.money > info.preMoney ? info.money - info.preMoney : 0"/></span></span>
          </div>
        </div>
        <div
          style="display: flex;justify-content: space-between;width: 400px;background: linear-gradient(-135deg, rgba(19, 194, 176, 0.2), 24%, rgb(255, 255, 255), rgb(255, 255, 255));margin-left: 225px;">
          <div style="margin-left: 40px;margin-top: 70px;display: flex;flex-direction: column;">
            <span style="font-size: 14px;color: #50565D;font-weight: bold">今日订单数</span>
            <span style="font-size: 28px;font-weight: bold;margin-top: 10px"><CountTo :startVal="0" :endVal="info.orderNum"/></span>
            <span style="font-size: 12px;color: #50565D;font-weight: bold;margin-top: 10px;">昨日订单数：<span><CountTo :startVal="0" :endVal="info.preOrderNum"/></span></span>
            <span style="font-size: 11px;margin-top: 10px;color: #86909C;">较昨日多出：<span
              style="color: red;"><CountTo :startVal="0" :endVal="info.orderNum > info.preOrderNum ? info.orderNum - info.preOrderNum : 0"/></span></span>
          </div>
          <img src="../../assets/img/home/right_icon.png"
               style="width: 150px;height: 50px;margin-right: 20px;margin-top: 20px">
        </div>
      </div>
      <div style="width: 1100px;height: 150px" class="leftBox">
        <div style="padding: 20px">
          <div style="width: 320px">
            <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
            <span style="font-weight: bold;font-size: 14px;">常用功能</span>
          </div>
          <div style="display: flex;margin-top: 10px;">
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-kehuguanli" @click="$router.push('/mch/mchList')"></i></el-col>
              <el-col class="text">商户管理</el-col>
            </el-row>
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-zhigeiwodebeifen" @click="$router.push('/mch/inputPiece')"></i></el-col>
              <el-col class="text">进件管理</el-col>
            </el-row>
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-zhuanjieguanlibeifen" @click="$router.push('/mch/integral')"></i></el-col>
              <el-col class="text">轮询管理</el-col>
            </el-row>
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-quanbuyingyongbeifen" @click="$router.push('/order/orderList')"></i></el-col>
              <el-col class="text">订单管理</el-col>
            </el-row>
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-a-kejichanyefuwubeifen2"  @click="$router.push('/pieceManager')"></i></el-col>
              <el-col class="text">审核管理</el-col>
            </el-row>
            <el-row class="rowMargin">
              <el-col><i class="iconfont el-icon-zonghenenghaobeifen" @click="$router.push('/channelManager')"></i></el-col>
              <el-col class="text">通道配置</el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div style="width: 1100px;height: 350px" class="leftBox">
        <div style="padding: 20px;">
          <div style="width: 100%">
            <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
            <span style="font-weight: bold;font-size: 14px;">交易流水统计</span>
            <div style="float: right;display: flex">
              <i class="el-icon-arrow-left" style="cursor: pointer;"></i>
              <span
                style="margin-left: 10px;margin-right:10px;font-size: 12px;font-weight: bold;">{{ new Date().getFullYear() + '年' + new Date().getMonth() + '月' }}</span>
              <i class="el-icon-arrow-right"
                 style="color: #50565D;background: rgba(134,144,156,0.3);border-radius: 50%;cursor: pointer"></i>
            </div>
          </div>
        </div>
        <div id="jylstj" style="height: 300px;width: 1100px"></div>
      </div>
      <div style="width: 1100px;height: 330px" class="leftBox">
        <div style="padding: 20px;">
          <div style="width: 100%">
            <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
            <span style="font-weight: bold;font-size: 14px;">通道与进件状态分布</span>
            <div style="display: flex;justify-content: space-between;width: 90%;margin-left: 70px;">
              <div id="tenantId" style="width: 500px;height: 300px"></div>
              <div id="remarkFl" style="width: 500px;height: 300px;margin-left: 10px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%">
      <div class="rightBox" style="height: 200px">
        <img src="@/assets/img/home/homeright.png" style="width: 500px;height: 200px;margin-left: 10px;cursor: pointer;">
      </div>
      <div class="rightBox" style="height: 400px">
        <div style="padding: 20px;">
          <div style="width: 100%;display: flex;justify-content: space-between;align-content: center;">
            <div>
              <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
              <span style="font-weight: bold;font-size: 14px;">通道跑量列表</span>
            </div>
            <span style="color: #86909C;font-size: 12px;cursor: pointer;" @click="$router.push('/pieceManager')">更多 <i class="el-icon-arrow-right"></i></span>
          </div>
          <div style="padding: 10px 20px 20px 20px;">
            <div style="display: flex;justify-content: space-between;margin-top: 20px;" v-for="(item,index) in channelList" :key="index">
              <div style="display: flex;flex-direction: column;">
                <div style="display:flex;">
                  <span style="font-size: 14px;font-weight: bold;margin-left: 15px;">C{{item.channelId}}通道</span>
                </div>
              </div>
              <span type="text" style="font-size: 12px;font-weight: bold">{{item.price}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="rightBox" style="height: 400px">
        <div style="padding: 20px;">
          <div style="width: 100%;display: flex;justify-content: space-between;align-content: center;">
            <div>
              <span style="background: #135AFA;width: 2px;border-radius: 4px;margin-right: 10px;">&nbsp;</span>
              <span style="font-weight: bold;font-size: 14px;">交易排行榜</span>
            </div>
            <span style="color: #86909C;font-size: 12px;cursor: pointer;"  @click="$router.push('/order/orderList')">更多 <i class="el-icon-arrow-right"></i></span>
          </div>
          <div style="padding: 0 20px 20px 20px;">
            <div style="display: flex;justify-content: space-between;margin-top: 25px;margin-left: 10px;" v-for="(item,index) in info.topMoney" :key="index">
              <div style="display: flex;flex-direction: column;">
                <div style="display:flex;">
                  <span style="font-size: 14px;font-weight: bold;margin-left: 15px;">{{ item.mchFor }}</span>
                </div>
                <span style="font-size: 12px;color: #86909C;margin-top: 5px;margin-left: 15px;">商户号：{{ item.userCode }}</span>
              </div>
              <span style="font-size: 14px;font-weight: bold"><CountTo :startVal="0" :endVal="item.money"/>元</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {getHomeInfo} from "@/api/agent/agent";
import {numberFormat} from "@/filters";
import CountTo from 'vue-count-to'
import {computingChannel} from "@/api/order/order";
export default {
  name: "Home",
  components:{CountTo},
  data() {
    return {
      info: {},
      channelList:[]
    }
  },
  mounted() {
     // this.getComputingChannel()
  },
  methods: {
    numberFormat,
    getComputingChannel(){
      computingChannel().then(res =>{
        this.channelList = res.data
      })
    }
  }
}
</script>

<style scoped>
.leftBox {
  border: 0px;
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
}

.rightBox {
  border: 0px;
  margin-top: 16px;
  margin-left: 16px;
  background: #ffffff;
  border-radius: 4px;
}

.iconfont {
  font-size: 50px;
}

.rowMargin {
  margin-left: 65px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;

  .text {
    margin-top: 10px;
  }
}

</style>
