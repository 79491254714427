import http from '@/libs/axios';

let pro = process.env.VUE_APP_BASE_API


/**
 * 查询交易的金额
 * @param data
 * @returns {*}
 */
export function totalPrice(query){
    return http({
        url: `${pro}/v1/order/totalPrice`,
        method: 'GET',
        params: query
    })
}

/**
 * 查询分账列表
 * @param data
 */
export function divisionList(data){
    return http({
        url: `${pro}/v1/division/list`,
        method: 'POST',
        requestBody: true,
        data
    })
}

/**
 * 查询结算列表
 * @param data
 * @returns {*}
 */
export function settleList(data){
    return http({
        url: `${pro}/v1/settle/list`,
        method: 'POST',
        requestBody: true,
        data
    })
}

/**
 * 查询应用下的金额
 * @param data
 * @returns {*}
 */
export function homeTotalPrice(data){
  return http({
    url: `${pro}/v1/order/homeTotalPrice`,
    method: 'POST',
    requestBody: true,
    data
  })
}

/**
 * 通过订单号查询信息信息
 * @param id
 * @returns {*}
 */
export function getByOrderNo(id){
    return http({
        url: `${pro}/v1/order/getByOrderNo/${id}`,
        method: 'POST'
    })
}

export function ysQbMoney(id){
  return http({
    url: `${pro}/v1/public/ysQbMoney/${id}`,
    method: 'post'
  })
}
export function sumGroupByMchIdPage(data){
  return http({
    url: `${pro}/v1/public/sumGroupByMchIdPage`,
    method: 'post',
    requestBody: true,
    data
  })
}


/**
 * 统计代理商 今天的代付笔数  金额   成功/失败
 * @param userNo
 * @returns {*}
 */
export function computingUserOrder(userNo){
    return http({
        url: `${pro}/order/computingUserOrder?userNo=${userNo}`,
        method: 'post'
    })
}
/**
 * 统计转账类型金额
 * @param userNo
 * @returns {*}
 */
export function computingOrderType(data){
    return http({
        url: `${pro}/order/computingOrderType`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 下载转账回单
 * @param userNo
 * @returns {*}
 */
export function downCredentials(orderNo){
    return http({
        url: `${pro}/downCredentials?orderNo=${orderNo}`,
        method: 'post'
    })
}


/**
 * 查询充值列表
 * @param userNo
 * @returns {*}
 */
export function payList(data){
    return http({
        url: `${pro}/order/payList`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 统计充值总金额
 * @param userNo
 * @returns {*}
 */
export function computingPayMoney(data){
    return http({
        url: `${pro}/order/computingPayMoney`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 统计通道跑量
 * @param userNo
 * @returns {*}
 */
export function computingChannel(){
    return http({
        url: `${pro}/order/computingChannel`,
        method: 'post'
    })
}

/**
 * 查询支付宝应用列表
 * @param userNo
 * @returns {*}
 */
export function appList(){
    return http({
        url: `${pro}/alipay/appList`,
        method: 'get'
    })
}


/**
 * 查询投诉列表
 * @param userNo
 * @returns {*}
 */
export function tsList(data){
    return http({
        url: `${pro}/alipay/tsList`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 处理投诉
 * @param userNo
 * @returns {*}
 */
export function clts(id,data){
    return http({
        url: `${pro}/alipay/clts/${id}`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 处理投诉
 * @param userNo
 * @returns {*}
 */
export function testPay(p,userNo,id){
  return http({
    url: `${pro}/pay/test/${p}/${id}/${userNo}`,
    method: 'get'
  })
}
